<template>
    <div class="topbar-item ml-4 mr-4 notificationBtn">
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg pulse pulse-primary" id="notiBell" @click="showMenuNotification">
            <span class="svg-icon svg-icon-xl svg-icon-primary" :class="{'text-danger': visibleMenu}">
                <!--begin::Svg Icon | path:assets/media/svg/icons/Code/Compiling.svg-->
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24" />
                        <path d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z" fill="#000000" opacity="0.3" />
                        <path d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z" fill="#000000" />
                    </g>
                </svg>
                <!--end::Svg Icon-->
            </span>
            <span class="badge badge-danger badge-pill" v-if="newNotifications">{{ newNotifications }}</span>
            <span class="pulse-ring" v-if="newNotifications"></span>
        </div>
        <Transition>
        <div class="menuNotifications" v-if="visibleMenu">
            <div class="titleNotifications">
                <h5 class="textTitleNotifications">Уведомления <span v-if="countNotifications">[{{ countNotifications }}]</span></h5>
                <a class="linkAll" href="/notifications/notification">Все</a>
            </div>
            <div class="blockNotificationList" data-simplebar="init" @scroll="onScroll">
                <div class="text-reset itemNotification" v-for="(item, index) in listNotifications" :index="index" v-if="countNotifications > 0">
                    <div class="contentNotification" :class="{'alert-success': !item.read}">
                        <div class="avatar-xs iconNotification">
                            <!-- <span class="avatar-title rounded-circle font-size-16" :class="item.class"><i :class="item.icon"></i></span> -->
                            <div class="avatar avatar-title rounded-circle font-size-16" :class="item.class">
                                <img :src="'/'+item.user.photo??'/img/noImage.jpg'" alt="">
                                <div class="type" :class="item.class"><i :class="item.icon"></i></div>
                            </div>
                        </div>
                        <div class="body">
                            <h6 class="label" v-html="item.label"></h6>
                            <div class="prevText" v-html="item.text"></div>
                            <div class="showMore" v-if="item.link"><a :href="item.link" alt="more">подробнее</a></div>
                            <div class="contentNotificationFooter"><span>{{ item.user.login }}</span><small>{{ item.date }}</small></div>
                        </div>
                    </div>
                    <div class="setShow" v-if="!item.read" @click="setShowNotification(index)"><i class="fas fa-eye"></i></div>
                </div>

                <h3 class="p-4 font-size-14 text-center bg-light" v-if="countNotifications == 0">Нет новых уведомлений</h3>

                <!-- <div class="loadNext" v-if="loadNext"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div> -->

            </div>
            <div class="border-top" v-if="newNotifications"><a class="btn btn-sm btn-info font-size-14 btn-block text-center" @click="setShowAllNotification()">Отметить всё прочтенным</a></div>
            <!-- <div class="border-top"><a class="btn btn-sm btn-dark font-size-14 btn-block text-center" href="/notifications/notification">Все уведомления</a></div> -->
        </div>
        </Transition>
    </div>
    <!-- <textarea >{{ notifications }}</textarea> -->
</template>
<script>
export default {
    props: {
        // arrValues: Array
    },
    data() {
        return {
            token: '',

            visibleMenu: false,
            notifications: [],
            lastId: null,
            countNotifications: 0,

            timing: null
        }
    },
    computed: {
        // countNotifications: function(){ return this.notifications.length; },
        loadNext: function(){ return this.notifications.length < this.countNotifications; },
        newNotifications: function(){
            let newN = 0;
            this.notifications.forEach(v => v.read == false ? newN++ : v);
            return newN;
        },
        listNotifications: function(){
            return this.notifications.sort((a, b) => { return b.id - a.id;});
        },
    },
    mounted() {
        this.token = document.querySelector("[name=csrf-token]").content;
        // this.timing = setInterval(this.loadNotifications(), 5000);
        // this.runTiming();
        this.loadNotifications(false);
        setInterval(()=>{ this.loadNotifications() }, 5000)
    },
    methods: {
        preventDefault(e){ e.preventDefault(); },
        showMenuNotification(toggle = true){
            console.log('shom notification');
            if(toggle) this.visibleMenu = !this.visibleMenu;
            if(!toggle) this.visibleMenu = false;
        },
        showNotification(index){
            // Swal.fire({ title: this.notifications[index].label, html: (this.notifications[index].html??this.notifications[index].text), type: 'info',  showCancelButton: false, confirmButtonText: 'Закрыть'});
            // this.notifications[index].read = true;
        },
        setShowNotification(index){
            // this.notifications[index].read = true;
            // let id = this.notifications[index];
            if(typeof this.notifications[index].id != "undefined" && this.notifications[index].id > 0) 
                this.setAjaxShowNotification(this.notifications[index].id, index);
        },
        setShowAllNotification(){
            this.notifications.forEach(function(v){ if(!v.read) v.read = true; });
            this.setAjaxShowNotification(0, null, true);
        },

        getBell(){
            this.soundBell();
            this.animateBell();
        },
        soundBell(){
            let audio = new Audio('/sound/icq.mp3');
            audio.play();
        },
        animateBell(){
            document.getElementById("notiBell").animate(
            [
                { transform: "rotate(-15deg)" },
                { transform: "rotate(0)" },
                { transform: "rotate(5deg)" },
                { transform: "rotate(0)" },
            ],
            {
                duration: 300,
                iterations: 7,
            },
          );
        },

        onScroll(e) {
            // console.log(e.target.offsetHeight);
            // console.log(e.target.scrollTop);
            // console.log(e.target.scrollHeight - 60);
            if (e.target.offsetHeight + e.target.scrollTop >= (e.target.scrollHeight - 60)) {
                this.loadParentElements();
            }
        },

        loadNotifications(songBell = true){
            let _this = this;
            console.log('get-list after: ' + this.lastId);
            axios.post('/notifications/get-list', 
                {data: {afterId: _this.lastId}},
                { headers: { 'Content-Type': 'application/json', 'X-CSRF-TOKEN': this.token }}
            )
            .then(function(res){
                // console.log(res.data);
                if(res.data.status){
                    if(typeof res.data.data.notifications != "undefined" && res.data.data.notifications.length > 0){
                        if(typeof res.data.data.lastId != "undefined") _this.lastId = res.data.data.lastId;
                        _this.notifications = [...res.data.data.notifications, ..._this.notifications];
                        _this.countNotifications = res.data.data.countAll;
                        console.log('notifications update');
                        if(_this.newNotifications){
                            _this.visibleMenu = false;
                            if(songBell) _this.getBell();
                        }
                    }
                } else {
                    console.log(res.data.message);
                    // window.getAlert('error', 'Ошибка', res.data.message);
                    // Swal.fire({ title: 'Ошибка!', text: res.data.message, type: 'error',  showCancelButton: false, confirmButtonText: 'Понятно'});
                }
            })
            .catch(function(error){
                console.log(error);
                let errorMessage = '';
                if(errorMessage == '' && typeof error.response.data.message != "undefined") errorMessage = error.response.data.message;
                if(errorMessage == '' && typeof error.message != "undefined") errorMessage = error.message;
                if(errorMessage == '') errorMessage = 'Не известная ошибка';
                console.log(errorMessage);
                // Swal.fire({ title: 'Ошибка!', text: errorMessage, type: 'error',  showCancelButton: false, confirmButtonText: 'Понятно'});
            });
            // if(this.newNotifications > 0) setTimeout(this.animateBell(), 1000);
        },

        setAjaxShowNotification(idElement = 0, index = null, setAll = false){
            let _this = this;
            axios.post('/notifications/set-read', 
                {data: {id: idElement, setAll: setAll}},
                { headers: { 'Content-Type': 'application/json', 'X-CSRF-TOKEN': this.token }}
            )
            .then(function(res){
                // console.log(res.data);
                if(res.data.status){
                    if(index != null) _this.notifications[index].read = true;
                    // _this.notifications.splice(index, 1);
                    console.log(res.data.message);
                } else {
                    console.log(res.data.message);
                }
            })
            .catch(function(error){
                console.log(error);
                let errorMessage = '';
                if(errorMessage == '' && typeof error.response.data.message != "undefined") errorMessage = error.response.data.message;
                if(errorMessage == '' && typeof error.message != "undefined") errorMessage = error.message;
                if(errorMessage == '') errorMessage = 'Не известная ошибка';
                console.log(errorMessage);
                // Swal.fire({ title: 'Ошибка!', text: errorMessage, type: 'error',  showCancelButton: false, confirmButtonText: 'Понятно'});
            });
            // if(this.newNotifications > 0) setTimeout(this.animateBell(), 1000);
        },

        loadParentElements(){
            let _this = this;
            console.log('get-list after elements: ' + this.lastId);
            axios.post('/notifications/get-list', 
                {data: {afterId: _this.lastId}},
                { headers: { 'Content-Type': 'application/json', 'X-CSRF-TOKEN': this.token }}
            )
            .then(function(res){
                console.log(res.data);
                if(res.data.status){
                    if(typeof res.data.data.notifications != "undefined" && res.data.data.notifications.length > 0){
                        if(typeof res.data.data.lastId != "undefined") _this.lastId = res.data.data.lastId;
                        _this.notifications = [..._this.notifications, ...res.data.data.notifications];
                        _this.countNotifications = res.data.data.countAll;
                        console.log('notifications update');
                        if(_this.newNotifications){
                            _this.visibleMenu = false;
                            if(songBell) _this.getBell();
                        }
                    }
                } else {
                    console.log(res.data.message);
                    // window.getAlert('error', 'Ошибка', res.data.message);
                    // Swal.fire({ title: 'Ошибка!', text: res.data.message, type: 'error',  showCancelButton: false, confirmButtonText: 'Понятно'});
                }
            })
            .catch(function(error){
                console.log(error);
                let errorMessage = '';
                if(errorMessage == '' && typeof error.response.data.message != "undefined") errorMessage = error.response.data.message;
                if(errorMessage == '' && typeof error.message != "undefined") errorMessage = error.message;
                if(errorMessage == '') errorMessage = 'Не известная ошибка';
                console.log(errorMessage);
                // Swal.fire({ title: 'Ошибка!', text: errorMessage, type: 'error',  showCancelButton: false, confirmButtonText: 'Понятно'});
            });
            // if(this.newNotifications > 0) setTimeout(this.animateBell(), 1000);
        },
    },
    beforeDestroy() {
        clearInterval(this.timing);
    }
}
</script>